
import { Options, Watch } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { namespace } from "s-vuex-class";
import TheoryBlock from "@/components/Pages/Home/Theory/TheoryBlock.vue";
import PlaygroundBlock from "@/components/Pages/Theory/PlaygroundBlock.vue";
import PreliminaryImg from "@/components/Pages/PreliminaryExam/PreliminaryImg.vue";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import { mixins } from "vue-class-component";
import StudentPreExamEducationMixin from "@/mixins/StudentEducations/StudentPreExamEducationMixin";
import StudentEducationsStoreMixin from "@/mixins/StudentEducations/StudentEducationsStoreMixin";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";
import { useIonRouter } from "@ionic/vue";
import { IStudentEducationWithTheory } from "@/interfaces/IStudentEducationWithTheory";

const StudentEducationModule = namespace("student-education");

@Options({
  components: {
    ForwardHeader,
    EducationTabs,
    PreliminaryImg,
    PlaygroundBlock,
    TheoryBlock,
    DefaultLayout,
  },
})
export default class Theory extends mixins(StudentPreExamEducationMixin, StudentEducationsStoreMixin) {
  private ionRouter = useIonRouter();

  private get preExamProgress() {
    return this.selectedEducation?.consecutiveSuccessfulPreExams || 0;
  }

  @StudentEducationModule.Getter("withTheory/getDataList")
  public studentEducationWithTheory!: Array<IStudentEducationWithTheory>;

  public filteredEducations: any = [];

  private onEducationClassChanged(selectedEducation: any): void {
    if (selectedEducation) {
      this.selectEducation(selectedEducation);
    }
  }

  public onClickPreliminaryExamCard(): void {
    this.ionRouter.navigate("/preliminary-exam", "forward", "push");
  }

  public get selectedStudentEducation(): IStudentEducationWithTheory | null {
    return this.selectedEducation;
  }

  @Watch("studentEducations", { deep: true, immediate: true })
  public onStudentEducationsWatch(educations: any) {
    if (educations) {
      this.filteredEducations = educations.filter((education: any) => education.theoryRequired);
      if (!this.filteredEducations.length) {
        this.$router.push({ name: "Home" });
      }
    }
  }
}
